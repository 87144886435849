import React, { useContext, useState, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Fade from 'react-reveal/Fade';
import Jump from 'react-reveal/Jump';
import PortfolioContext from '../../context/context';
import WeedmapsLogo from '../../svgs/weedmaps_logo.svg';
import Dc101 from '../../svgs/dc101.svg';
import IheartRadio from '../../svgs/iheartradio.svg';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, cta, body, subbody1, subbody2, featured, reviews, img, emailSubject, emailBody, weedmapsHref, callMobileHref, mailToHref, proceeds } = hero;

  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 769) {
      setIsDesktop(true);
      setIsMobile(false);
    } else {
      setIsMobile(true);
      setIsDesktop(false);
    }
  }, []);

  const buttonHref = () =>
    isMobile ?
      callMobileHref :
      mailToHref

  return (
    <section id="hero" className="jumbotron">
      <Container>
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={500} distance="30px">
          <h1 className="hero-title">
            {title} <span className="text-color-main">with Dr. Vitaly Stepensky</span>
          </h1>
        </Fade>
        <div className='br' />
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1000} distance="30px">
          <h1 className="dark-blue-text">{body}</h1>
        </Fade>
        <div className='br' />
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={1500} distance="30px">
          <h1 className="dark-blue-text">{subbody1}</h1>
        </Fade>
        <div className='br' />
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={2000} distance="30px">
          <h1 className="dark-blue-text">{subbody2}</h1>
        </Fade>
        <div className='br' />
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={2500} distance="30px">
        <h1 className="dark-blue-text">{proceeds}</h1>
      </Fade>
        <div className='br' />
        <Fade left={isDesktop} bottom={isMobile} duration={1000} delay={3000} distance="30px">
          <p className="hero-cta">
            <a className="cta-btn cta-btn--hero" href={buttonHref()}>
              {cta}
            </a>
          </p>
        </Fade>
        <Fade
          left={isDesktop}
          bottom={isMobile}
          duration={1000}
          delay={3500}
          distance="30px"
        >
          <div className='svg-block'>
            <Jump delay={4000}>
              <>
                <h1 className="dark-blue-text">{featured}</h1>
                <a href={weedmapsHref}>
                  <WeedmapsLogo className='weedmaps-svg' />
                </a>
                <p className="dark-blue-text stars">{reviews}</p>
              </>
            </Jump>
          </div>
        </Fade>
      </Container>
    </section>
  );
};

export default Header;
